html,
body {
  font-size: 100%;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  background: #f4f8fe !important;
}
:root {
  --primaryColor: #ff4646;
  --secondaryColor: #050c44;
  --tertiaryColor: #eaf2fa;
  --backgroundColor: #f4f8fe;
  --links: #cdceda;
  --whiteColor: #ffffff;
  --inactiveColor: #8188a6;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 10px;
}
.container-fluid {
  width: 100%;
  padding: 0px !important;
}
.container {
  width: 80%;
  padding: 0px !important;
}

@font-face {
  font-family: OpenSans-extra-bold;
  src: url(../src/fonts/OpenSans-ExtraBold.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-bold;
  src: url(../src/fonts/OpenSans-Bold.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-light;
  src: url(../src/fonts/OpenSans-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-regular;
  src: url(../src/fonts/OpenSans-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-semi-bold;
  src: url(../src/fonts/OpenSans-SemiBold.ttf);
  font-display: swap;
}

/* Italic Fonts */
@font-face {
  font-family: OpenSans-italic;
  src: url(../src/fonts/OpenSans-Italic.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-light-italic;
  src: url(../src/fonts/OpenSans-LightItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-semi-bold-italic;
  src: url(../src/fonts/OpenSans-SemiBoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-bold-italic;
  src: url(../src/fonts/OpenSans-BoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: OpenSans-extrabold-italic;
  src: url(../src/fonts/OpenSans-ExtraBoldItalic.ttf);
  font-display: swap;
}

/* Utility classes: FONT_FAMILY */
.font-extra-bold {
  margin: 0;
  font-family: OpenSans-extra-bold;
}
.font-bold {
  margin: 0;
  font-family: OpenSans-bold !important;
}
.font-semi-bold {
  margin: 0;
  font-family: OpenSans-semi-bold;
}
.font-regular {
  font-family: OpenSans-regular;
}
.font-light {
  margin: 0;
  font-family: OpenSans-light;
}
.font-extra-bold-italic {
  margin: 0;
  font-family: OpenSans-extra-bold;
}
.font-italic {
  margin: 0;
  font-family: OpenSans-italic;
}
.font-semi-bold-italic {
  margin: 0;
  font-family: OpenSans-semi-bold-italic;
}
.font-bold-italic {
  margin: 0;
  font-family: OpenSans-bold-italic;
}
.font-light-italic {
  margin: 0;
  font-family: OpenSans-light-italic;
}

/* Utility Classes: FONT_SIZE */
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px !important;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px !important;
}
.font-25 {
  font-size: 25px;
}
.font-40 {
  font-size: 40px;
}
.text-secondaryColor {
  color: var(--secondaryColor);
}
.text-primaryColor {
  color: var(--primaryColor);
}
.text-inactiveColor {
  color: var(--inactiveColor) !important;
}
.text-linksColor{
  color: var(--links) !important;
}
.text-whiteColor {
  color: var(--whiteColor) !important;
}
a:hover {
  text-decoration: none !important;
}

.bg-primaryColor {
  background: var(--primaryColor) !important;
}
.bg-secondaryColor {
  background: var(--secondaryColor) !important;
}
.bg-tertiaryColor {
  background: var(--tertiaryColor) !important;
}
.bg-backgroundColor {
  background: var(--backgroundColor);
}
.bg-linksColor{
  background: var(--links) !important;
}

/* Mutual */
p {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.004em;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
}
h2.sub-heading {
  font-size: 30px;
}
.bg-light-orange {
  background: #fffaf4 !important;
}
.underlined-heading {
  border-bottom: 3px solid var(--darkOrange);
  width: 65%;
}
@media (max-width: 768px) {
  .underlined-heading {
    border-bottom: 3px solid var(--darkOrange);
    width: 100%;
  }
}
.py-section {
  padding: 100px 0px !important;
}
@media only screen and (max-width: 991px) {
  .py-section {
    padding: 80px 0px !important;
  }
}
/* @media only screen and (max-width: 1200px) {
  .line::after{
    top: 82%;
    left: 120%;
    transform: translate(-120%,-82%);
    content: '';
    width: 300px;
    background-color: #192358;
  }
  .line-2::after{
    top: 82%;
    left: 96%;
    transform: translate(-96%,-82%);
    content: '';
    width: 20px;
    background-color: #FFFFFF;
  }
} */
.p-100 {
  padding: 100px 0px !important;
}
.p-75 {
  padding: 75px 0px !important;
}
.p-50 {
  padding: 50px 0px !important;
}
.p-25 {
  padding: 25px 0px !important;
}
.center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card,
.card-header {
  background: transparent !important;
  border: none !important;
}
.letter-spacing-1 {
  letter-spacing: -1.5px !important;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.cetQhm {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: #091744 !important;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 0px 0px rgba(103, 58, 183, 1) !important;
  border-radius: 50%;
  outline: none;
}
.bQjoUN {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: transparent;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 2px #ff4646 !important;
  border-radius: 50%;
  outline: none;
}
/* .bSWeAW {
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.6em;
  background-color: transparent !important;
  color: var(--secondaryColor) !important;
  box-shadow: 0 0 4px 0px #ff4646 !important;
  border-radius: 50%;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  outline: none;
} */

.border-left-nav {
  border-left: 2px solid #ff4646 !important;
}
.border-left-dept {
  border-left: 4px solid #ff4646 !important;
}
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* dropdowns */
#collasible-nav-dropdown {
  color: #ffffff;
}
#basic-nav-dropdown {
  color: var(--secondaryColor);
}
#dropdown-button-drop-right {
  color: var(--whiteColor);
  font-size: 14px;
  margin-left: 10px;
}

#dropdown-button-drop-right:focus {
  color: var(--whiteColor);
  outline: 5px solid var(--secondaryColor) !important;
}

#dept-nav-dropdown{
  color: #ffffff;
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 25px !important;
  height: 100%;
  width: 1px !important;
  background: #fff;
}
.dropdown-item:hover {
  background: var(--primaryColor) !important;
  font-weight: bold !important;
  /* color: var(--whiteColor) !important; */
}
.dropdown-menu.show {
  padding: 0px !important;
}

.dropdown-item-headnav:hover {
  background: var(--primaryColor) !important;
  font-weight: bold !important;
  color: var(--whiteColor);
}

.chart-space {
  height: auto;
  width: 600px;
  margin-top: 0px;
}

@media (max-width: 760px) {
  .chart-space {
    width: 500px;
  }
}
@media (max-width: 400px) {
  .chart-space {
    width: 330px;
  }
}

/* .bg-white{
  background: white !important;
} */

.border-bottom-tertiary{
  border-bottom: 2px solid var(--tertiaryColor) !important;
}

.border-bottom-background{
  border-bottom: 2px solid var(--backgroundColor) !important;
}

.border-top-tertiary{
 border-top: 2px solid var(--tertiaryColor) !important;
}

.border-top-background{
  border-top: 2px solid var(--backgroundColor) !important;
 }

.ol-list{
  list-style-type: upper-roman !important;
}
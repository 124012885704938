.dropdown-item-bottom{    
    border-bottom: 1px solid #192358 ;
}

.hr{
    background-color: #CDCEDA !important;
}

@media screen and (max-width: 772px){
    #nav5{
        font-size: 14px !important;
    }
}